<template>
    <div class="wscn-http404-container d-flex align-items-center">
        <div class="wscn-http404">
            <div class="pic-404">
                <img class="pic-404__parent" :src="icon404" alt="404">
                <img class="pic-404__child left" :src="icon404Cloud" alt="404">
                <img class="pic-404__child mid" :src="icon404Cloud" alt="404">
                <img class="pic-404__child right" :src="icon404Cloud" alt="404">
            </div>
            <div class="bullshit">
                <div class="bullshit__oops">
                    OOPS!
                </div>
                <div class="bullshit__headline">
                    {{ message }}
                </div>
                <DxButton @click="$router.push('/')" class="button-default-fill" type="default" text="Back to home" />
            </div>
        </div>
    </div>
</template>

<script>
import icon404 from "../../assets/img/404.png";
import icon404Cloud from "../../assets/img/404_cloud.png";
import { DxButton } from "devextreme-vue"

export default {
    name: "Page404",
    components: { DxButton },
    data() {
        return {
            icon404,
            icon404Cloud,
            message: "The bodyguard said that you can\'t enter this page..."
        };
    }
};
</script>